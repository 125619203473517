export default function guest({ next, store }) {
  const url = document.location.pathname;
  const isCheckoutPage = url.startsWith("/checkout");

  if (store.state.auth.token && !isCheckoutPage) {
    return next({
      name: "Dashboard",
    });
  }

  return next();
}
