import auth from "../middleware/auth";

const routes = [
  {
    path: "/my-recordings",
    name: "MyRecordings",
    component: () => import("../../views/protected/Recordings"),
    meta: {
      layout: () => import("../../layouts/protected"),
      middleware: [auth],
    },
  },
  {
    path: "/my-recording-details/:id",
    name: "MyRecordingDetails",
    component: () =>
      import("../../components/RecordDetails/MyRecordingDetails"),
    meta: {
      layout: () => import("../../layouts/protected"),
      middleware: [auth],
    },
  },
  {
    path: "/received-recordings",
    name: "ReceivedRecordings",
    component: () => import("../../views/protected/ReceivedRecordings"),
    meta: {
      layout: () => import("../../layouts/protected"),
      middleware: [auth],
    },
  },
  {
    path: "/record",
    name: "RecordScreen",
    component: () => import("../../views/protected/RecordScreen"),
    meta: {
      layout: () => import("../../layouts/public"),
    },
  },
  {
    path: "/request/:personal_url",
    name: "SendScreenRecording",
    component: () => import("../../views/protected/SendScreenRecording"),
    meta: {
      layout: () => import("../../layouts/public"),
    },
  },
  {
    path: "/requested-links",
    name: "RequestedLinks",
    component: () => import("../../views/protected/RequestedLinks"),
    meta: {
      layout: () => import("../../layouts/protected"),
      middleware: [auth],
    },
  },
  {
    path: "/share/:id",
    name: "ShareRecording",
    component: () => import("../../views/protected/ShareRecording"),
    meta: {
      layout: () => import("../../layouts/public"),
    },
  },
];

export default routes;
