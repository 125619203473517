export default function auth({ next, store }) {
  const url = document.location.pathname;
  const params = new URLSearchParams(window.location.search);
  const verified = params.get("verified");
  if (!store.state.auth.token) {
    return next({
      name: "Login",
      query: { redirect: url },
    });
  }
  if (
    store.state.auth.token &&
    !store.state.auth.user.email_verified_at &&
    !verified
  ) {
    return next({
      name: "VerifyEmail",
      query: { redirect: url },
    });
  }
  if (
    store.state.auth.token &&
    !store.state.auth.user.email_verified_at &&
    verified
  ) {
    store.commit("auth/LOGOUT");
    return next({
      path: "/sign_in?verified=true",
    });
  }
  return next();
}
