import auth from "../middleware/auth";
const routes = [
  {
    path: "/onboarding",
    name: "Onboarding",
    component: () => import("../../views/protected/Onboarding"),
    meta: {
      layout: () => import("../../layouts/public"),
      middleware: [auth],
    },
  },
  {
    path: "/select-account",
    name: "SelectAccount",
    component: () => import("../../views/protected/SelectAccount"),
    meta: {
      layout: () => import("../../layouts/public"),
      middleware: [auth],
    },
  },
  {
    path: "/dashboard",
    name: "Dashboard",
    component: () => import("../../views/protected/Dashboard"),
    meta: {
      layout: () => import("../../layouts/protected"),
      middleware: [auth],
    },
  },
];

export default routes;
