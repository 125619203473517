const state = {};

const getters = {};

const mutations = {};

const actions = {
  async get_members() {
    return window.axios.get("/teams");
  },
  async add_member(_, payload) {
    return window.axios.post("/teams/add", payload);
  },
  async update_member(_, payload) {
    return window.axios.patch("/teams/update/" + payload.id, payload);
  },
  async remove_member(_, user) {
    return window.axios.delete("/teams/delete/" + user.id);
  },
  async remove_members(_, ids) {
    return window.axios.post("/teams/multiple/delete", { ids: ids });
  },
  async get_accounts() {
    return await window.axios.get("teams/accounts");
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
