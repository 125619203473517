const state = {
  folderDetails: [],
  load_view_folders: [],
  archives_folders: [],
  trashes_folders: [],
};
const getters = {
  folderDetails(state) {
    return state.folderDetails;
  },
  load_view_folders(state) {
    return state.load_view_folders;
  },
  archives_folders(state) {
    return state.archives_folders;
  },
  trashes_folders(state) {
    return state.trashes_folders;
  },
};
const mutations = {
  SHOW_DETAILS(state, data) {
    state.folderDetails = data;
  },
  LOAD_VIEW_FOLDERS(state, data) {
    state.load_view_folders = data;
  },
  LOAD_ARCHIVE_FOLDERS(state, data) {
    state.archives_folders = data;
  },
  LOAD_TRASHED_FOLDERS(state, data) {
    state.trashes_folders = data;
  },
};
const actions = {
  async my_folders({ commit }) {
    const response = await window.axios.get("/folders/list");
    commit("SHOW_DETAILS", response.data);
    return response;
  },
  async user_folders_list(_, userId) {
    return await window.axios.get("/folders/" + userId + "/user-list");
  },
  async my_archives({ commit }) {
    const response = await window.axios.get("/folders/archives");
    commit("LOAD_ARCHIVE_FOLDERS", response.data);
    return response;
  },
  async my_trashes({ commit }) {
    const response = await window.axios.get("/folders/deleted");
    commit("LOAD_TRASHED_FOLDERS", response.data);
    return response;
  },
  async show_folder_details({ commit }, showId) {
    const response = await window.axios.get("/folders/show/" + showId);
    commit("LOAD_VIEW_FOLDERS", response.data);
    return response;
  },
  async move_folders_list(_, exclude_folder_id) {
    return await window.axios.get(
      "/folders/list/recursive/" + exclude_folder_id
    );
  },
  async create_new_folder(_, form) {
    return await window.axios.post("/folders/create", form);
  },
  async rename_folder(_, form) {
    return await window.axios.patch("/folders/update/" + form.id, form);
  },
  async move_folder_to_folder(_, form) {
    return await window.axios.post("/folders/move-folder/" + form.id, form);
  },
  async move_folder_to_root(_, Id) {
    return await window.axios.post("/folders/move-folder-to-root/" + Id);
  },
  async move_recording_to_folder(_, form) {
    return await window.axios.post(
      "/folders/move-recordings/" + form.folder_id,
      form
    );
  },
  async duplicate_folder(_, form) {
    return window.axios.post("/folders/" + form.id + "/duplicate", form);
  },
  async delete_folder(_, form) {
    return window.axios.delete("/folders/" + form.id + "/delete");
  },
  async archive_folder(_, form) {
    return window.axios.patch("/folders/archive-folder/" + form.id);
  },
  async unarchive_folder(_, form) {
    return window.axios.patch("/folders/unarchive-folder/" + form.id);
  },
  async restore_folder(_, form) {
    return window.axios.patch("/folders/restore-folder/" + form.id);
  },
  async forced_delete_folder(_, form) {
    return window.axios.delete("/folders/forced-delete/" + form.id);
  },
};
export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
