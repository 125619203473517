import auth from "../middleware/auth";

const routes = [
  {
    path: "/support",
    name: "Support",
    component: () => import("../../views/protected/Support"),
    meta: {
      layout: () => import("../../layouts/protected"),
      middleware: [auth],
    },
  },
];

export default routes;
