<template>
  <div>
    <v-snackbar :color="color" v-model="snackbar">
      {{ snackBarText }}
    </v-snackbar>
  </div>
</template>

<script>
export default {
  props: {
    snackbar: {
      type: Boolean,
      default: false,
    },
    snackBarText: {
      type: String,
      default: "",
    },
    color: {
      type: String,
      default: "",
    },
  },
};
</script>
