import auth from "../middleware/auth";

const routes = [
  {
    path: "/custom-domain-v2",
    name: "Custom Domain",
    component: () => import("../../views/protected/CustomDomain"),
    meta: {
      layout: () => import("../../layouts/protected"),
      middleware: [auth],
    },
  },
  {
    path: "/custom-domain",
    name: "custom Domain",
    component: () => import("../../views/protected/CustomDomainV2"),
    meta: {
      layout: () => import("../../layouts/protected"),
      middleware: [auth],
    },
  },
];

export default routes;
