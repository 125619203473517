import auth from "../middleware/auth";

const routes = [
  {
    path: "/my-folders",
    name: "MyFolders",
    component: () => import("../../views/protected/MyFoldersView"),
    props: true,
    meta: {
      layout: () => import("../../layouts/protected"),
      middleware: [auth],
    },
  },
  {
    path: "/folder-view/:id",
    name: "FoldersView",
    component: () => import("../../views/protected/FoldersView"),
    props: true,
    meta: {
      layout: () => import("../../layouts/protected"),
      middleware: [auth],
    },
  },
];

export default routes;
