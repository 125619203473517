<template>
  <v-app>
    <component :is="layout">
      <router-view></router-view>
    </component>
  </v-app>
</template>

<script>
import { mapActions } from "vuex";
export default {
  name: "App",
  data() {
    return {
      timerId: null,
      timer: 0,
    };
  },
  watch: {
    timer(val) {
      if (val > 0) {
        this.timerId = setTimeout(() => {
          this.lastSeen();
        }, 60000);
      }
    },
    $route(to) {
      const isRecord = to.path === "/record";
      const isRequestRoute = to.path.startsWith("/request/");
      if (isRecord || isRequestRoute) {
        this.applyCustomStyles();
      } else {
        this.applyDefaultStyles();
      }
    },
  },
  computed: {
    layout() {
      return this.$route.meta.layout;
    },
  },
  methods: {
    ...mapActions("users", ["last_seen"]),
    startTimer() {
      this.timerId = setTimeout(() => {
        this.timer = 1;
      }, 120000);
    },
    async lastSeen() {
      try {
        await this.last_seen();
        this.timer = 0;
      } catch (e) {
        this.timer = 0;
      }
    },
    applyCustomStyles() {
      const styleElement = document.querySelector("style[data-custom-styles]");
      if (!styleElement) {
        const customStyleElement = document.createElement("style");
        customStyleElement.setAttribute("data-custom-styles", "");
        customStyleElement.innerHTML = `
          .v-list-item--dense .v-list-item__content,
          .v-list--dense .v-list-item .v-list-item__content {
            padding: 0px !important;
            margin: 0px !important;
            font-size: 5px !important;
            text-align: left;
          }
          .v-list-item--dense .v-list-item__title,
          .v-list-item--dense .v-list-item__subtitle,
          .v-list--dense .v-list-item .v-list-item__title,
          .v-list--dense .v-list-item .v-list-item__subtitle {
            font-size: 10px !important;
            font-weight: 500 !important;
            line-height: 1rem !important;
          }
          .v-list-item--dense,
          .v-list--dense .v-list-item {
            min-height: 30px !important;
          }
          .v-application--is-ltr .v-text-field--outlined fieldset {
            border: 1px solid #eee !important;
          }
        `;
        document.head.appendChild(customStyleElement);
      }
    },
    applyDefaultStyles() {
      const styleElement = document.querySelector("style[data-custom-styles]");
      if (styleElement) {
        styleElement.remove();
      }
    },
  },
  beforeRouteEnter(to, from, next) {
    console.info(from);
    const isRecord = to.path === "/record";
    const isRequestRoute = to.path.startsWith("/request/");
    if (isRecord || isRequestRoute) {
      next((vm) => vm.applyCustomStyles());
    } else {
      next();
    }
  },
  beforeRouteUpdate(to, from, next) {
    console.info(from);
    const isRecord = to.path === "/record";
    const isRequestRoute = to.path.startsWith("/request/");
    if (isRecord || isRequestRoute) {
      this.applyCustomStyles();
    } else {
      this.applyDefaultStyles();
    }
    next();
  },
  mounted() {
    if (this.$store.state.auth.token) {
      document.addEventListener("click", this.startTimer);
      document.addEventListener("keydown", this.startTimer);
      document.addEventListener("mousemove", this.startTimer);
    }
  },
  beforeDestroy() {
    clearTimeout(this.timerId);
  },
};
</script>

<style lang="scss">
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

#nav {
  padding: 30px;

  a {
    font-weight: bold;
    color: #2c3e50;

    &.router-link-exact-active {
      color: #42b983;
    }
  }
}
</style>
