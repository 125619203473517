import auth from "../middleware/auth";

const routes = [
  {
    path: "/account-settings",
    name: "AccountSettings",
    component: () => import("../../views/protected/Profile"),
    meta: {
      layout: () => import("../../layouts/protected"),
      middleware: [auth],
    },
  },
  {
    path: "/record-settings",
    name: "RecordSettings",
    component: () => import("../../views/protected/RecordSettings"),
    meta: {
      layout: () => import("../../layouts/protected"),
      middleware: [auth],
    },
  },
];

export default routes;
