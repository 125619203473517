import auth from "../middleware/auth";

const routes = [
  {
    path: "/reseller-license",
    name: "Users",
    component: () => import("../../views/protected/Users"),
    meta: {
      layout: () => import("../../layouts/protected"),
      middleware: [auth],
    },
  },
  {
    path: "/team-members",
    name: "TeamMembers",
    component: () => import("../../views/protected/TeamMembers"),
    meta: {
      layout: () => import("../../layouts/protected"),
      middleware: [auth],
    },
  },
  {
    path: "/contacts",
    name: "Contacts",
    component: () => import("../../views/protected/Contacts"),
    meta: {
      layout: () => import("../../layouts/protected"),
      middleware: [auth],
    },
  },
];

export default routes;
