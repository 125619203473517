const state = {};

const getters = {};

const mutations = {};

const actions = {
  async all_users(_, page) {
    return await window.axios.get("/user/all-users" + page);
  },
  async user_details(_, data) {
    return await window.axios.get(
      "/user?duration=" + data.duration + "&type=" + data.type
    );
  },
  async last_seen() {
    return await window.axios.post("/user/last-seen");
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
