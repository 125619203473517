import Vue from "vue";
import Toast from "vue-toastification";
import "vue-toastification/dist/index.css";
import { POSITION } from "vue-toastification";

Vue.use(Toast, {
  position: POSITION.BOTTOM_RIGHT,
});

export default class ResponseHelper {
  coreResponse(type, message) {
    if (type === "error") {
      Vue.$toast.error(message);
    } else {
      Vue.$toast.success(message);
    }
  }

  sendSuccess(response) {
    return this.coreResponse("success", response.message);
  }

  sendError(error) {
    let status = error.response.status;
    let message = "";
    let msg = error.response.data.message;
    if (status === 422) {
      let errors = error.response.data.errors;
      errors = Object.keys(errors)
        .map((e) => errors[e])
        .flat();
      message = errors[0];
    } else if (status === 500) {
      message = "Something went wrong, please try again";
    } else {
      message = msg;
    }
    return this.coreResponse("error", message);
  }

  staticError(message) {
    return this.coreResponse("error", message);
  }
}
