"use strict";

import Vue from "vue";
import axios from "axios";
import store from "../store";
import router from "../router";

const url =
  process.env.NODE_ENV === "production"
    ? process.env.VUE_APP_PRODUCTION_URL
    : process.env.VUE_APP_STAGING_URL;

let config = {
  baseURL: url,
  //timeout: 60 * 5000, // Timeout
};

const _axios = axios.create(config);

_axios.interceptors.request.use(
  function (config) {
    // Do something before request is sent
    const token = store.state.auth.token;
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    } else {
      delete config.headers.Authorization;
    }
    return config;
  },
  function (error) {
    // Do something with request error
    return Promise.reject(error);
  }
);

// Add a response interceptor
_axios.interceptors.response.use(
  function (response) {
    // Do something with response data
    response = response.data;
    return response;
  },
  async function (error) {
    // Do something with response error
    if (error.response.status === 401) {
      store.commit("auth/LOGOUT");
      const route = document.location.pathname;
      await router.push({
        path: "/sign_in",
        query: { redirect: route },
      });
    }
    if (error.response.data.message === "Your email address is not verified.") {
      await store.commit("auth/LOGOUT");
      await router.push({
        name: "Login",
      });
    }
    return Promise.reject(error);
  }
);

Plugin.install = function (Vue) {
  Vue.axios = _axios;
  window.axios = _axios;
  Object.defineProperties(Vue.prototype, {
    axios: {
      get() {
        return _axios;
      },
    },
    $axios: {
      get() {
        return _axios;
      },
    },
  });
};

Vue.use(Plugin);

export default Plugin;
