import auth from "../middleware/auth";

const routes = [
  {
    path: "/account-upgrade",
    name: "AccountUpgrade",
    component: () => import("../../views/protected/AccountUpgrade"),
    meta: {
      layout: () => import("../../layouts/protected"),
      middleware: [auth],
    },
  },
  {
    path: "/payment-method",
    name: "PaymentMethod",
    component: () => import("../../views/protected/PaymentMethod"),
    meta: {
      layout: () => import("../../layouts/protected"),
      middleware: [auth],
    },
  },
  {
    path: "/payment-history",
    name: "PaymentHistory",
    component: () => import("../../views/protected/PaymentHistory"),
    meta: {
      layout: () => import("../../layouts/protected"),
      middleware: [auth],
    },
  },
  {
    path: "/payment-successful",
    name: "PaymentSuccessful",
    component: () => import("../../components/PaymentCompleted"),
    meta: {
      layout: () => import("../../layouts/protected"),
      middleware: [auth],
    },
  },
];

export default routes;
