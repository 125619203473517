import Vue from "vue";
import VueRouter from "vue-router";
import store from "../store/index";
import publicRoutes from "./public";
import protectedRoutes from "./protected";

Vue.use(VueRouter);

const routes = [...publicRoutes, ...protectedRoutes];

// noinspection JSUnusedGlobalSymbols
const router = new VueRouter({
  scrollBehavior() {
    return { x: 0, y: 0 };
  },
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

const nextFactory = (context, middleware, index) => {
  const subsequentMiddleware = middleware[index];
  if (!subsequentMiddleware) return context.next;

  return (...parameters) => {
    context.next(...parameters);

    const nextMiddleware = nextFactory(context, middleware, index + 1);
    subsequentMiddleware({ ...context, next: nextMiddleware });
  };
};

const allowPathForCustomDomain = (to, next, accountType) => {
  if (
    to.path === "/custom-domain" &&
    accountType &&
    accountType !== "Enterprise" &&
    accountType !== "Pro Basic" &&
    accountType !== "Pro Premium" &&
    accountType !== "AppSumo Tier 2" &&
    accountType !== "AppSumo Tier 3"
  ) {
    next({
      path: "dashboard",
    });
  } else {
    next();
  }
  const url = process.env.VUE_APP_URL;
  const path = window.location.href;
  if (path.indexOf(url) === -1) {
    if (
      path.indexOf("record") > -1 ||
      path.indexOf("request") > -1 ||
      path.indexOf("share") > -1
    ) {
      return next();
    } else {
      window.location.href = url;
    }
  }
};

router.beforeEach((to, from, next) => {
  const accountType = store.state.auth.user.account_type;
  allowPathForCustomDomain(to, next, accountType);
  if (
    to.path === "/verify-email" &&
    !to.meta["middleware"] &&
    !store.state.auth.token
  ) {
    next({
      name: "Login",
    });
  }
  if (to.path === "/users") {
    if (
      accountType &&
      accountType !== "Pro Basic" &&
      accountType !== "Pro Premium" &&
      !accountType.includes("Pro")
    ) {
      next({
        name: "Dashboard",
      });
    } else {
      next();
    }
  }
  if (to.meta["middleware"]) {
    const middleware = to.meta["middleware"];

    const context = {
      to,
      from,
      next,
      store,
    };

    const nextMiddleware = nextFactory(context, middleware, 1);

    return middleware[0]({
      ...context,
      next: nextMiddleware,
    });
  } else {
    next();
  }
});

export default router;
