const state = {
  default_domain: {},
};

const getters = {
  default_domain(state) {
    return state.default_domain;
  },
};

const mutations = {
  SET_DEFAULT(state, data) {
    state.default_domain = data;
    localStorage.setItem("favicon", data.favicon_url);
    localStorage.setItem("tagline", data.tagline);
    localStorage.setItem("domain_logo", data.domain_logo_url);
    localStorage.setItem("active_domain", data.name);
    localStorage.setItem("active_domain_id", data.id);
    let checkDefault = data.tagline !== undefined ? true : false;
    localStorage.setItem("is_custom_domain", checkDefault);
  },
  CUSTOMIZE_DEFAULT(state, data) {
    state.default_domain = data;
    localStorage.setItem("is_custom_domain", false);
  },
  CLEAR_DEFAULT_DOMAIN(state) {
    let keysToRemove = ["favicon", "tagline", "domain_logo"];
    for (let item of keysToRemove) {
      localStorage.removeItem(item);
    }
    localStorage.setItem("is_custom_domain", false);
    state.default_domain = {};
  },
};

const actions = {
  async add_domain(_, form) {
    return window.axios.post("/domains/create", form);
  },
  async get_domains({ commit }) {
    const response = await window.axios.get("/domains");
    const domain = response.data.find((x) => x.default === "yes");
    if (domain) {
      commit("SET_DEFAULT", domain);
    } else {
      commit("CUSTOMIZE_DEFAULT", domain);
    }
    return response;
  },
  async get_single_domain({ commit }, domainId) {
    const response = await window.axios.get("/domains/" + domainId);
    commit("SET_DEFAULT", response.data);
    return response;
  },
  async mark_as_default(_, item) {
    return window.axios.patch(`/domains/mark-default/${item.id}`, item);
  },
  async upload_favicon(_, form) {
    return window.axios.post("/domains/upload-favicon", form);
  },
  async remove_domain(_, item) {
    return window.axios.delete(`/domains/delete/${item.id}`);
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
