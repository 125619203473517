const routes = [
  {
    path: "/verify-email",
    name: "VerifyEmail",
    component: () => import("../../views/protected/VerifyEmail"),
    meta: {
      layout: () => import("../../layouts/public"),
    },
  },
];

export default routes;
