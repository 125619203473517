const state = {
  card: {},
};

const getters = {
  card(state) {
    return state.card;
  },
};

const mutations = {
  CARD_DETAILS(state, data) {
    state.card = data;
  },
};

const actions = {
  async payment_methods({ commit }, form) {
    const response = await window.axios.post("/payments/methods/create", form);
    commit("CARD_DETAILS", response.data);
    return response;
  },
  async payment_history(_, page) {
    return await window.axios.get("/payments" + page);
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
