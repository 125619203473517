const state = {
  settings: {},
};

const getters = {
  settings(state) {
    return state.settings;
  },
};

const mutations = {
  SETTINGS(state, data) {
    state.settings = data;
  },
  CLEAR_SETTINGS(state) {
    state.settings = {};
  },
};

const actions = {
  async settings_update({ commit }, form) {
    const response = await window.axios.post("/settings/create", form);
    commit("SETTINGS", response.data);
    return response;
  },
  async get_categories() {
    return await window.axios.get("/categories");
  },
  async get_settings({ commit }, domainId) {
    const response = await window.axios.get("/settings/user/" + domainId);
    commit("SETTINGS", response.data);
    return response;
  },
  async add_account({ commit }, form) {
    const response = await window.axios.post("/accounts/add-account", form);
    commit("auth/USER_DATA", response.data, { root: true });
    return response;
  },
  async edit_account(_, form) {
    return window.axios.patch("/accounts/edit-account/" + form.user_id, form);
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
