import Vue from "vue";
import Vuex from "vuex";
import createPersistedState from "vuex-persistedstate";
import SecureLS from "secure-ls";

const ls = new SecureLS({ isCompression: false });
Vue.use(Vuex);

import auth from "./modules/auth";
import recordings from "./modules/recordings";
import folders from "./modules/folders";
import profile from "./modules/profile";
import payments from "./modules/payments";
import settings from "./modules/settings";
import users from "./modules/users";
import domain from "./modules/domain";
import contacts from "./modules/contacts";
import teams from "./modules/teams";

export default new Vuex.Store({
  modules: {
    auth,
    recordings,
    folders,
    profile,
    payments,
    settings,
    users,
    domain,
    contacts,
    teams,
  },
  plugins: [
    createPersistedState({
      key: process.env.VUE_APP_NAME,
      storage: {
        getItem: (key) => ls.get(key),
        setItem: (key, value) => ls.set(key, value),
        removeItem: (key) => ls.remove(key),
      },
    }),
  ],
});
