import "./assets/styles/custom.css";
import "vue-toastification/dist/index.css";

import Vue from "vue";
import "./plugins/axios";
import App from "./App.vue";
import vuetify from "./plugins/vuetify";
import router from "./router";
import store from "./store";
import Toast from "vue-toastification";
import { POSITION } from "vue-toastification";
import VueApexCharts from "vue-apexcharts";
import Flutterwave from "vue-flutterwave";
import VueSocialSharing from "vue-social-sharing";
import SnackBar from "./utils/Snackbar.vue";
import ResponseHelper from "./utils/ResponseHelper";

Vue.config.productionTip = false;

Vue.use(Toast, {
  position: POSITION.BOTTOM_RIGHT,
});
Vue.use(VueApexCharts);
Vue.use(Flutterwave, { publicKey: process.env.VUE_APP_FLKEY });
Vue.use(VueSocialSharing);

Vue.component("ApexChart", VueApexCharts);
Vue.component("snackbar", SnackBar);

Vue.prototype.$response = new ResponseHelper();

new Vue({
  vuetify,
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
