import dashboard from "./dashboard";
import recordings from "./recordings";
import profile from "./profile";
import upgrade from "./upgrade";
import support from "./support";
import users from "./users";
import verify from "./verify";
import domains from "./domains";
import folders from "./folders";
import training from "./training";

const routes = [
  ...dashboard,
  ...recordings,
  ...profile,
  ...upgrade,
  ...support,
  ...users,
  ...verify,
  ...domains,
  ...folders,
  ...training,
];

export default routes;
