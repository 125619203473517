const state = {
  user: {},
  token: null,
};

const getters = {
  user(state) {
    return state.user;
  },
};

const mutations = {
  USER_DATA(state, data) {
    state.user = data;
  },
  AUTH_TOKEN(state, data) {
    state.token = data.token;
  },
  LOGOUT(state) {
    state.user = {};
    state.token = null;
    window.localStorage.removeItem("skip_extension");
    window.localStorage.removeItem("user");
  },
};

const actions = {
  async sign_up(_, form) {
    return await window.axios.post("/auth/register", form);
  },

  async sign_in({ commit, dispatch }, form) {
    const response = await window.axios.post("/auth/login", form);
    commit("USER_DATA", response.data);
    commit("AUTH_TOKEN", response.data);
    window.localStorage.setItem("user", JSON.stringify(response.data));
    if (response.data.email_verified_at !== null) {
      dispatch("domain/get_domains", null, { root: true });
    }
    return response;
  },

  async forgot_password(_, form) {
    return await window.axios.post("/forgot-password", form);
  },

  async reset_password(_, form) {
    return await window.axios.post("/reset-password", form);
  },

  async resend_verification_link() {
    return await window.axios.post("/email/verify/resend");
  },

  async select_account({ commit, dispatch }, form) {
    const response = await window.axios.post("/auth/select-account", form);
    commit("USER_DATA", response.data);
    window.localStorage.setItem("user", JSON.stringify(response.data));
    if (response.data.email_verified_at !== null) {
      dispatch("domain/get_domains", null, { root: true });
    }
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
