const state = {};

const getters = {};

const mutations = {};

const actions = {
  async get_contacts(_, page) {
    return window.axios.get("/contacts" + page);
  },
  async add_contact(_, payload) {
    return window.axios.post("/contacts", payload);
  },
  async delete_contact(_, id) {
    return window.axios.delete("/contacts/" + id);
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
