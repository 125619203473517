const state = {};

const getters = {
  user(state, getters, rootState, rootGetters) {
    return rootGetters["auth/user"];
  },
};

const mutations = {};

const actions = {
  async update_profile({ commit }, form) {
    const response = await window.axios.post("/user/update", form);
    commit("auth/USER_DATA", response.data, { root: true });
    return response;
  },
  async change_password(_, form) {
    return await window.axios.post("/user/change-password", form);
  },
  async delete_account() {
    return await window.axios.post("/user/delete-account");
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
